import axios from "../interceptors/interceptors";
import { GetCdoShortName, GetCdoUid } from "../data/CDO";
import { DefaultLanguageIsoCode } from "../data/languageData";
import { HandleApiErrorMiddleware } from "./ApiErrorHandler";
import config from "../config";


const handleError = (err: any) => {
  return HandleApiErrorMiddleware("SignConsentFormsAPI", err);
};

const Actions = {
  info: "info",
  createSignatureImage: "createSignatureImage",
  signAndPreviewPdf: "signAndPreviewPdf",
  signAndSavePdf: "signAndSavePdf",
  bulkSignAndPreviewPdfs: "bulkSignAndPreviewPdfs",
  bulkSignAndSavePdfs: "bulkSignAndSavePdfs",
  reviewExistingPdfs: "reviewExistingPdfs",
};

const _runSignConsentFormsActionAPI = async (
  action: string,
  patientId: string,
  appointmentId: string,
  appointmentTypeId: string,
  languageIsoCode: string,     // this is an ISO language code
  templateKey: string | string[],
  cdoFormType: string | string[],
  customData: any,
  base64: string
): Promise<any> => {
  let route: string = "pci-signpdfforms";
  let apiCfg: any = config.signConsentFormsApi;
  let url: string = apiCfg.url + route;
  if (apiCfg.code) { 
    url += "?code=" + apiCfg.code;
  }

  // default to english if we don't have a language set
  if (!languageIsoCode) {
    languageIsoCode = DefaultLanguageIsoCode;
  }

  if (Array.isArray(templateKey)) {
    templateKey = templateKey.join(",");
  }
  if (Array.isArray(cdoFormType)) { 
    cdoFormType = cdoFormType.join(",");
  }

  let cdoShortName = GetCdoShortName();
  let params: any = {
    action,
    cdoId: cdoShortName,
    patientId,
    appointmentId,
    appointmentTypeId,
    languageCode: languageIsoCode,
    templateKey,
    cdoFormType,
    customData,
    base64
  };

  let headers: any = {
    "Content-Type": "application/json",
  };

  let blobResponse: boolean = !!(action === Actions.signAndPreviewPdf);
  let responseType: any = blobResponse ? "blob" : undefined;

  let req: any = {
    method: "POST",
    url,
    headers,
    responseType,
    data: JSON.stringify(params),
  };

  return  axios(req)
    .then((res) => {
      return res?.data;
    })
    .catch(handleError);
};

export const signSingleConsentFormAPI = async (
  patientId: string,
  appointmentId: string,
  appointmentTypeId: string,
  languageIsoCode: string,
  templateKey: string,
  cdoFormTypes: string,
  customData: any,
  base64:string
): Promise<any> => {
  return _runSignConsentFormsActionAPI(
    Actions.signAndSavePdf,
    patientId,
    appointmentId,
    appointmentTypeId,
    languageIsoCode,
    templateKey,
    cdoFormTypes,
    customData,
    base64
  );
};

export const bulkSignConsentFormsAPI = async (
  patientId: string,
  appointmentId: string,
  appointmentTypeId: string,
  languageIsoCode: string,
  templateKeys: string | string[],
  cdoFormTypes: string | string[],
  customData: any,
  base64:string
): Promise<any> => {
  return _runSignConsentFormsActionAPI(
    Actions.bulkSignAndSavePdfs,
    patientId,
    appointmentId,
    appointmentTypeId,
    languageIsoCode,
    templateKeys,
    cdoFormTypes,
    customData,
    base64
  );
};

export const reviewExistingConsentFormsAPI = async (
  patientId: string,
  appointmentId: string,
  bundleId: string
): Promise<any> => {
  let route: string = "pci-signpdfforms";
  let apiCfg: any = config.signConsentFormsApi;
  let url: string = apiCfg.url + route + "?code=" + apiCfg.code;

  let action: string = Actions.reviewExistingPdfs;
  let cdoUid = GetCdoUid();
  let cdoShortName = GetCdoShortName();
  let params: any = {
    action,
    cdoUid,
    cdoId: cdoShortName,
    patientId,
    appointmentId,
    bundleId,
  };

  let headers: any = {
    "Content-Type": "application/json",
  };

  let req: any = {
    method: "POST",
    url,
    headers,
    data: JSON.stringify(params),
  };

  return axios(req)
    .then((res) => {
      return res?.data;
    })
    .catch(handleError);
};

export const getReviewExistingConsentFormsAPIUrl = (
  patientId: string,
  appointmentId: string,
  bundleId: string,
  preview: boolean = false
): string => {
  let route: string = "pci-signpdfforms";
  let apiCfg: any = config.signConsentFormsApi;
  let url: string = apiCfg.url + route + "?code=" + apiCfg.code;

  let action: string = Actions.reviewExistingPdfs;
  let cdoUid = GetCdoUid();
  let cdoShortName = GetCdoShortName();
  let params: any = {
    action,
    preview,
    cdoUid,
    cdoId: cdoShortName,
    patientId,
    appointmentId,
    bundleId,
  }; 
  let paramsStr: string = Object.entries(params).map(([k, v]) => (`${k}=${v}`)).join("&");
  url += "&" + paramsStr;
  return url;
};
