import React, { useState } from 'react';
import './customstyles.css'
import { aemLanguageOptions, preferredPronounsItems, genderItems, sexAssignedBirthItems, maritalStatusItems, ethnicityItems, raceItems } from './staticdata';
import { apicall_PatientData } from './savedata';
import { useAppSelector } from '../../app/hooks';
import { Grid, InputLabel, TextField, Typography } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

interface Props {
    // Define your component props here
}

const PatientRegistrationSpa: React.FC<Props> = () => {
    // Component logic goes here     

    const pdata: any = useAppSelector((state) => state.patient);
    const pInsurence: any = useAppSelector((state) => state.insurance);
    const persona = useAppSelector((state) => state.preferences.persona);
    const relation = useAppSelector((state) => state.preferences.relationship);
    const representativeName=useAppSelector((state)=>state.preferences.representativeFullName);
    /* Modal data start */
    const [formData, setFormData] = useState({
        patientId: pdata.patientId,
        first_name: pdata.first_name,
        last_name: pdata.last_name,
        full_name: pdata.full_name,
        timeStamp:pdata.timeStamp,
        demographics_sexAtBirth: pdata.demographics.sexAtBirth,
        demographics_genderIdentity: pdata.demographics.genderIdentity,
        demographics_preferredPronoun: pdata.demographics.preferredPronoun,
        PreferredName:pdata.PreferredName,
        demographics_religiousAffiliation: pdata.demographics.religiousAffiliation,
        demographics_maritalStatus: pdata.demographics.maritalStatus,
        demographics_race: pdata.demographics.race,
        demographics_ethnicity: pdata.demographics.ethnicity,
        middle_name: pdata.middle_name,
        other_name: pdata.other_name,
        other_GI: pdata.other_GI,
        other_SAbirth: pdata.other_SAbirth,
        emergencyContact_address: pdata.emergencyContact_address,
        emergencyContact_apt: pdata.emergencyContact_apt,
        emergencyContact_city: pdata.emergencyContact_city,
        emergencyContact_state: pdata.emergencyContact_state,
        emergencyContact_zip: pdata.emergencyContact_zip,
        emergencyContact_workPhoneext: pdata.emergencyContact_workPhoneext,
        PrintName: pdata.PrintName,
        relationship: pdata.relationship,
        filled_date: pdata.filled_date,
        //values
        demographics_sexAtBirthValue: pdata.demographics.sexAtBirthValue,
        demographics_genderIdentityValue: pdata.demographics.genderIdentityValue,
        demographics_preferredPronounValue: pdata.demographics.preferredPronounValue,
        demographics_religiousAffiliationValue: pdata.demographics.religiousAffiliationValue,
        demographics_maritalStatusValue: pdata.demographics.maritalStatusValue,
        demographics_raceValue: pdata.demographics.raceValue,
        demographics_ethnicityValue: pdata.demographics.ethnicityValue,
        email: pdata.email,
        consentToEmail: pdata.consentToEmail,
        consentToText: pdata.consentToText,
        homePhone: pdata.homePhone,
        workPhone: pdata.workPhone,
        workPhoneExtn: pdata.workPhoneExtn,
        cellPhone: pdata.cellPhone,
        preferredPhoneType: pdata.preferredPhoneType,
        preferredPhoneTypeCode: pdata.preferredPhoneTypeCode,
        address1: pdata.address.address1,
        address2: pdata.address.address2,
        city: pdata.address.city,
        state: pdata.address.state,
        zipcode: pdata.address.zipcode,
        dob: pdata.dob,
        isAdultPatient: pdata.isAdultPatient,  // true if patient has valid DOB and is over 18 years old
        cdoLanguageKey: pdata.cdoLanguageKey,   // this is the CDO specific language key from AEM for the current CDO.  This is not the ISO language code
        cdoLanguageName: pdata.cdoLanguageName,  // this is the CDO specific display name for the language in the current selected language
        photoid_front: pdata.photoid_front,
        photoid_back: pdata.photoid_back,
        emergencyContact_first_name: pdata.emergencyContact.first_name,
        emergencyContact_last_name: pdata.emergencyContact.last_name,
        emergencyContact_full_name: pdata.emergencyContact.full_name,
        emergencyContact_relationship: pdata.emergencyContact.relationship,
        emergencyContact_email: pdata.emergencyContact.email,
        emergencyContact_homePhone: pdata.emergencyContact.homePhone,
        emergencyContact_workPhone: pdata.emergencyContact.workPhone,
        emergencyContact_cellPhone: pdata.emergencyContact.cellPhone,
        representative_name: pdata.representative.name,
        representative_firstname: pdata.representative_firstname,
        representative_middlename: pdata.representative_middlename,
        representative_lastname: pdata.representative_lastname,
        representative_driver_license: pdata.representative_driver_license,
        representative_email: pdata.representative_email,
        representative_cellPhone: pdata.representative_cellPhone,
        representative_workPhone: pdata.representative_workPhone,
        representative_workPhoneext: pdata.representative_workPhoneext,
        representative_relationship: pdata.representative_relationship,
        representative_dob: pdata.representative.dob,
        representative_address1: pdata.representative.address.address1,
        representative_address2: pdata.representative.address.address2,
        representative_city: pdata.representative.address.city,
        representative_state: pdata.representative.address.state,
        representative_zipcode: pdata.representative.address.zipcode,
        representative_homePhone: pdata.representative.homePhone,
        representative_photoid_front: pdata.representative.photoid_front,
        representative_photoid_back: pdata.representative.photoid_back,
        advancedDirective_selection: pdata.advancedDirective.selection,
        accountBalance: pdata.accountBalance,
        representative_relation: pdata.representative_relation,
        representative_fullname: pdata.representative_fullname,
        comments: pdata.comments,
        witness_name: pdata.witness_name,
        witness_signature: pdata.witness_signature,


        coverage1_fhirInsuranceId: pInsurence.coverage1.fhirInsuranceId,
        coverage1_payorDisplay: pInsurence.coverage1.payorDisplay,
        coverage1_memberId: pInsurence.coverage1.memberId,
        coverage1_groupNumber: pInsurence.coverage1.groupNumber,
        coverage1_policyHolderName: pInsurence.coverage1.policyHolderName,
        coverage1_policyHolderDOB: pInsurence.coverage1.policyHolderDOB,
        coverage1_policyHolderRelationToPatientCode: pInsurence.coverage1.policyHolderRelationToPatientCode,
        coverage1_policyHolderRelationToPatientValue: pInsurence.coverage1.policyHolderRelationToPatientValue,
        coverage1_insuranceCardFrontImage: pInsurence.coverage1.insuranceCardFrontImage,
        coverage1_insuranceCardBackImage: pInsurence.coverage1.insuranceCardBackImage,
        coverage1_order: pInsurence.coverage1.order,

        coverage2_fhirInsuranceId: pInsurence.coverage2.fhirInsuranceId,
        coverage2_payorDisplay: pInsurence.coverage2.payorDisplay,
        coverage2_memberId: pInsurence.coverage2.memberId,
        coverage2_groupNumber: pInsurence.coverage2.groupNumber,
        coverage2_policyHolderName: pInsurence.coverage2.policyHolderName,
        coverage2_policyHolderDOB: pInsurence.coverage1.policyHolderDOB,
        coverage2_policyHolderRelationToPatientCode: pInsurence.coverage2.policyHolderRelationToPatientCode,
        coverage2_policyHolderRelationToPatientValue: pInsurence.coverage2.policyHolderRelationToPatientValue,
        coverage2_insuranceCardFrontImage: pInsurence.coverage2.insuranceCardFrontImage,
        coverage2_insuranceCardBackImage: pInsurence.coverage2.insuranceCardBackImage,


        hasSelfCoverage: pInsurence.hasSelfCoverage,
        hasCoverage1: pInsurence.hasCoverage1,
        hasCoverage2: pInsurence.hasCoverage2,
        insuranceProviderPayors: []

    });
    /* Modal data end */

    /* Change and Select events start */
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const [formState, setFormState] = useState({
        demographics_genderIdentity: formData.demographics_genderIdentity,
        demographics_sexAtBirth: formData.demographics_sexAtBirth,
        demographics_maritalStatus: formData.demographics_maritalStatus,
        demographics_ethnicity: formData.demographics_ethnicity,
        demographics_race: formData.demographics_race,
    });
    const [formInfo, setFormInfo] = useState({
        cdoLanguageKey: formData.cdoLanguageKey,
        cdoLanguageName: formData.cdoLanguageName,
        emergencyContact_relationship: formData.emergencyContact_relationship,
        demographics_preferredPronoun: formData.demographics_preferredPronoun,
        demographics_preferredPronounValue: formData.demographics_preferredPronounValue,
        coverage1_payorDisplay: formData.coverage1_payorDisplay,
    });
    const handleChange = (event) => {
        const { name, value } = event.target;
        let updatedFormInfo = { ...formInfo };
        if (name === 'preferredPronoun') {
            const selectedPronoun = preferredPronounsItems.find(item => item.Key === value);
            updatedFormInfo = {
                ...updatedFormInfo,
                demographics_preferredPronoun: value,
                demographics_preferredPronounValue: selectedPronoun ? selectedPronoun.Value : ''
            };
        } else if (name === 'cdoLanguage') {
            const selectedLanguage = aemLanguageOptions.find(item => item.Key === value);
            updatedFormInfo = {
                ...updatedFormInfo,
                cdoLanguageKey: value,
                cdoLanguageName: selectedLanguage ? selectedLanguage.Value : ''
            };
        }
        setFormInfo(updatedFormInfo);
        formData.cdoLanguageKey = updatedFormInfo.cdoLanguageKey;
        formData.cdoLanguageName = updatedFormInfo.cdoLanguageName;
        formData.demographics_preferredPronoun = updatedFormInfo.demographics_preferredPronoun;
        formData.demographics_preferredPronounValue = updatedFormInfo.demographics_preferredPronounValue
    };
    const updation = (field, key, value) => {
        formData[field] = key
        formData[field + "Value"] = value
    }
    const handleCheckChange = (event) => {
        const { name, value } = event.target;
        // console.log(value)
        const arrValues = value.split(',');
        setFormState(() => ({
            ...formState,
            [name]: arrValues[0],
        }));
        updation(name, arrValues[0], arrValues[1]);
    };
    const today = new Date();
    const formattedDate = `${String(today.getMonth() + 1).padStart(2, '0')}/${String(today.getDate()).padStart(2, '0')}/${today.getFullYear()}`;
    const handleSelectChange = (event) => {
        const { name, value } = event.target;
        if (name == "coverage1_payorDisplay") {
            setFormInfo({
                ...formInfo,
                [name]: value
            });
        }
        else {
            setFormInfo({
                ...formInfo,
                [name]: value
            });
        }
    };

    const getValue = () => {
        switch (formData.demographics_preferredPronoun) {
          case 'He/Him/His':
            return 'EL/su';
          case 'She/Her/Hers':
            return 'Ella/su';
          case  'They/Them/Theirs':
            return 'Ellos/sus';
          default:
            return formData.demographics_preferredPronoun;
        }
    };

    formData.emergencyContact_relationship = formInfo.emergencyContact_relationship;
    formData.coverage1_payorDisplay = formInfo.coverage1_payorDisplay;

    /* Change and Seelct events end */

    /*Save Modal construction start*/
    apicall_PatientData(formData);
    /*Save Modal construction end*/
    return (
        <>
            <div style={{ padding: '15px' }} id='mainDiv'>
                <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px' }} id="pageOne">
                    <form>
                        <Grid container spacing={2} alignItems="center" lineHeight={2.5}>
                            <Grid item xs={6}>
                                <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold' }}>
                                    Registro del paciente
                                </Typography>
                            </Grid>
                            <Grid item xs={6} textAlign='right'>
                                <img src={`${process.env.PUBLIC_URL}/Optum_logo.png`} alt="Optum Logo" style={{ width: '150px', height: '60px' }} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} alignItems="left" lineHeight={2.5}>
                            <Grid item xs={6}>
                                <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
                                    Información del paciente (en letra de molde)
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} alignItems="center" lineHeight={2.5}>
                            <Grid item xs={1}>
                                <InputLabel htmlFor="input1">Apellido</InputLabel>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField id="standard-basic" label="" variant="standard" value={formData.last_name} fullWidth />
                            </Grid>
                            <Grid item xs={1}>
                                <InputLabel htmlFor="input1">Nombre</InputLabel>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField id="standard-basic" label="" variant="standard" value={formData.first_name} fullWidth />
                            </Grid>
                            <Grid item xs={2} textAlign='right'>
                                <InputLabel htmlFor="input1">Segundo nombre</InputLabel>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField id="standard-basic" label="" variant="standard" value={formData.middle_name} fullWidth />
                            </Grid>
                            <Grid item xs={2}>
                                <InputLabel htmlFor="input1">Nombre preferido</InputLabel>
                            </Grid>
                            <Grid item xs={9.5}>
                                <TextField id="standard-basic" label="" variant="standard" value={formData.PreferredName} fullWidth />
                            </Grid>
                            <Grid item xs={4.2}>
                                <InputLabel htmlFor="input1">Otras nombres con los que también se le conoce</InputLabel>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField id="standard-basic" label="" variant="standard" value={formData.other_name} fullWidth />
                            </Grid>
                            <Grid item xs={2} textAlign='right'>
                                <InputLabel htmlFor="input1">Fecha de nacimiento</InputLabel>
                            </Grid>
                            <Grid item xs={2.5}>
                                <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.dob} />
                            </Grid>
                            <Grid item xs={2.5}>
                                <InputLabel htmlFor="input1">Identidad de género</InputLabel>
                            </Grid>
                            <Grid item xs={9.5}>
                                <FormControlLabel control={<Checkbox checked={genderItems[3].Value === formData.demographics_genderIdentity} />} label="Hombre" />
                                <FormControlLabel control={<Checkbox checked={genderItems[0].Value === formData.demographics_genderIdentity} />} label="Mujer" />
                                <FormControlLabel control={<Checkbox />} label="No binario" />
                                <FormControlLabel control={<Checkbox />} label="Género queer" />
                                <FormControlLabel control={<Checkbox checked={genderItems[5].Value === formData.demographics_genderIdentity}/>} label="Prefiero no revelar" />
                                <FormControlLabel control={<><Checkbox checked={formData.demographics_genderIdentity && (['Male','Female','Prefer Not to Disclose'].indexOf(formData.demographics_genderIdentity) === -1)} /><InputLabel htmlFor="input1">Otra</InputLabel><TextField id="standard-basic" label="" variant="standard" value={(['Male', 'Female','Prefer Not to Disclose','Other'].indexOf(formData.demographics_genderIdentity) === -1) ? formData.demographics_genderIdentityValue : ''} style={{marginLeft:'10px',width: '350px'}}/></>} label="" />
                            </Grid>
                            <Grid item xs={2.5}>
                                <InputLabel htmlFor="input1">Sexo asignado al nacer</InputLabel>
                            </Grid>
                            <Grid item xs={9.5}>
                                <FormControlLabel control={<Checkbox checked={'Masculino'=== formData.demographics_sexAtBirthValue} />} label="Hombre" />
                                <FormControlLabel control={<Checkbox checked={'Femenino' === formData.demographics_sexAtBirthValue} />} label="Mujer" />
                                <FormControlLabel control={<Checkbox />} label="No binario" />
                                <FormControlLabel control={<Checkbox />} label="Género queer" />
                                <FormControlLabel control={<Checkbox />} label="Prefiero no revelar" />
                                <FormControlLabel control={<><Checkbox checked={formData.demographics_sexAtBirthValue && (['Femenino', 'Masculino'].indexOf(formData.demographics_sexAtBirthValue)=== -1)} /><InputLabel htmlFor="input1">Otra</InputLabel><TextField id="standard-basic" label="" variant="standard" value={(['Femenino', 'Masculino'].indexOf(formData.demographics_sexAtBirthValue)=== -1) ? formData.demographics_sexAtBirthValue: ''} style={{marginLeft:'10px',width: '350px'}}/></>} label="" />
                            </Grid>
                            <Grid item xs={2.5} >
                                <InputLabel htmlFor="input1">Estado civil</InputLabel>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControlLabel control={<Checkbox checked={maritalStatusItems[0]["Key"] === formData.demographics_maritalStatus} />} label="Soltero" />
                                <FormControlLabel control={<Checkbox checked={'In a relationship' === formData.demographics_maritalStatus} />} label="En una relación" />
                                <FormControlLabel control={<Checkbox checked={maritalStatusItems[1]["Key"] === formData.demographics_maritalStatus} />} label="Casado" />
                                <FormControlLabel control={<Checkbox checked={maritalStatusItems[2]["Key"] === formData.demographics_maritalStatus} />} label="Vuido" />
                                <FormControlLabel control={<Checkbox checked={maritalStatusItems[3]["Key"] === formData.demographics_maritalStatus} />} label="Separado" />
                                <FormControlLabel control={<Checkbox checked={maritalStatusItems[4]["Key"] === formData.demographics_maritalStatus} />} label="Divorciado" />
                            </Grid>
                            <Grid container style={{ border: "1px solid black", padding: '15px', margin: '10px' }} spacing={1} lineHeight={2.5}>
                                <Grid item xs={12} >
                                    <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
                                        Información necesaria
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>Dirección</InputLabel>
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.address1} />
                                </Grid>
                                <Grid item xs={1} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>Apt.</InputLabel>
                                </Grid>
                                <Grid item xs={4} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.address2} />
                                </Grid>
                                <Grid item xs={1} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>Ciudad</InputLabel>
                                </Grid>
                                <Grid item xs={2} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.state} />
                                </Grid>
                                <Grid item xs={1} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>Estado</InputLabel>
                                </Grid>
                                <Grid item xs={2} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.city} />
                                </Grid>
                                <Grid item xs={2} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>Código Postal</InputLabel>
                                </Grid>
                                <Grid item xs={2} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.zipcode} />
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
                                        Números de teléfono (marque la casilla de su número de contacto preferido)
                                    </Typography>
                                </Grid>
                                <Grid item xs={1.1} >
                                    <FormControlLabel control={<Checkbox checked={'homePhone' === formData.preferredPhoneType} />} label="Hogar" />
                                </Grid>
                                <Grid item xs={4.9} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.homePhone.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')} />
                                </Grid>
                                <Grid item xs={1.1} >
                                    <FormControlLabel control={<Checkbox checked={'cellPhone' === formData.preferredPhoneType} />} label="Celular" />
                                </Grid>
                                <Grid item xs={4.9} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.cellPhone} />
                                </Grid>
                                <Grid item xs={1.1} >
                                    <FormControlLabel control={<Checkbox checked={'workPhone' === formData.preferredPhoneType} />} label="Trabajo" />
                                </Grid>
                                <Grid item xs={4.9} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.workPhone.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')} />
                                </Grid>
                                <Grid item xs={2.6} >
                                    <FormControlLabel control={<Checkbox checked={'workPhoneExtn' === formData.preferredPhoneType} />} label="Extensión del trabajo" />
                                </Grid>
                                <Grid item xs={3.4} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.workPhoneExtn} />
                                </Grid>
                                <Grid item xs={2} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>Correo electrónico</InputLabel>
                                </Grid>
                                <Grid item xs={7} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.email} />
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography variant="body2" gutterBottom>
                                        Al proporcionar su correo electrónico arriba, usted reconoce que los correos electrónicos pueden contener
                                        su Información de Salud Protegida y se enviarán sin cifrar. Existe un riesgo de interceptación o divulgación del contenido de estos correos electrónicos.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container style={{ margin: '5px' }} spacing={1} lineHeight={2.5}>
                                <Grid item xs={12} >
                                    <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
                                        Contacto de emergencia
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>Apellido</InputLabel>
                                </Grid>
                                <Grid item xs={3} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.emergencyContact_last_name} />
                                </Grid>
                                <Grid item xs={1} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>Nombre</InputLabel>
                                </Grid>
                                <Grid item xs={3} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.emergencyContact_first_name} />
                                </Grid>
                                <Grid item xs={1} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>Relación</InputLabel>
                                </Grid>
                                <Grid item xs={3} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.emergencyContact_relationship} />
                                </Grid>
                                <Grid item xs={1} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>Dirección</InputLabel>
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.emergencyContact_address} />
                                </Grid>
                                <Grid item xs={1} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>Apt.</InputLabel>
                                </Grid>
                                <Grid item xs={4} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth />
                                </Grid>
                                <Grid item xs={1} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>Ciuad </InputLabel>
                                </Grid>
                                <Grid item xs={3} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.emergencyContact_city} />
                                </Grid>
                                <Grid item xs={1} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>Estado</InputLabel>
                                </Grid>
                                <Grid item xs={3} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.emergencyContact_state} />
                                </Grid>
                                <Grid item xs={1.5} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>Código Postal</InputLabel>
                                </Grid>
                                <Grid item xs={2.5} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.emergencyContact_zip} />
                                </Grid>
                                <Grid item xs={2} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>Teléfono residencial</InputLabel>
                                </Grid>
                                <Grid item xs={4} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.emergencyContact_homePhone} />
                                </Grid>
                                <Grid item xs={1} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>Celular</InputLabel>
                                </Grid>
                                <Grid item xs={5} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.emergencyContact_cellPhone.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')} />
                                </Grid>
                                <Grid item xs={1} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>Trabajo</InputLabel>
                                </Grid>
                                <Grid item xs={5} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.emergencyContact_workPhone} />
                                </Grid>
                                <Grid item xs={2} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>Extensión del trabajo</InputLabel>
                                </Grid>
                                <Grid item xs={4} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.emergencyContact_workPhoneext} />
                                </Grid>
                            </Grid>
                            <Grid container style={{ border: "1px solid black", padding: '15px', margin: '10px' }} spacing={1} lineHeight={2.5}>
                                <Grid item xs={12} >
                                    <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
                                        Información necesaria
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} style={{ marginTop: '8px' }}>
                                    <InputLabel htmlFor="input1">Origen étnico(seleccione uno)</InputLabel>
                                </Grid>
                                <Grid item xs={9}>
                                    <FormControlLabel control={<Checkbox checked={ethnicityItems[0]["Key"] === formData.demographics_ethnicity} />} label="Origen hispano o latino o español" />
                                    <FormControlLabel control={<Checkbox checked={ethnicityItems[2]["Key"] === formData.demographics_ethnicity} />} label="Prefiero no revelar" />
                                    <FormControlLabel control={<Checkbox checked={ethnicityItems[1]["Key"] === formData.demographics_ethnicity} />} label="No de origen hispano, latino ni español" />
                                </Grid>
                                <Grid item xs={3} style={{ marginTop: '8px' }}>
                                    <InputLabel htmlFor="input1">Raza (seleccione una)</InputLabel>
                                </Grid>
                                <Grid item xs={9}>
                                    <FormControlLabel control={<Checkbox checked={raceItems[0]["Key"] === formData.demographics_race} />} label="Indio americano/nativo de Alaska" />
                                    <FormControlLabel control={<Checkbox checked={raceItems[1]["Key"] === formData.demographics_race} />} label="Asiático" />
                                    <FormControlLabel control={<Checkbox checked={raceItems[2]["Key"] === formData.demographics_race} />} label="Negro o afroamericano" />
                                    <FormControlLabel control={<Checkbox checked={raceItems[4]["Key"] === formData.demographics_race} />} label="Blanco o caucásico" />
                                    <FormControlLabel control={<Checkbox checked={raceItems[3]["Key"] === formData.demographics_race} />} label="Nativo de Hawái o de las islas del Pacífico" />
                                    <FormControlLabel control={<Checkbox checked={raceItems[5]["Key"] === formData.demographics_race} />} label="Prefiero no revelar" />
                                </Grid>
                                <Grid item xs={2}>
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>Idioma preferido</InputLabel>
                                </Grid>
                                <Grid item xs={10}>
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.cdoLanguageName} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} textAlign={'right'}>
                                <Typography variant="h6" gutterBottom>
                                    1
                                </Typography>
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <div style={{ height: '10px' }}></div>
                <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px' }} id="pageTwo">
                    <Grid container spacing={1} alignItems="center" lineHeight={2.0}>
                        <Grid item xs={1.5}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>Ocupación</InputLabel>
                        </Grid>
                        <Grid item xs={10.5}>
                            <TextField id="standard-basic" label="" variant="standard" value={''} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>¿Alguna vez ha sido paciente en algún centro de Optum?</InputLabel>
                        </Grid>
                        <Grid item xs={5}>
                            <FormControlLabel control={<Checkbox />} label="Sí"  
                            componentsProps={{
                                    typography: {
                                        style: { fontSize: '0.85rem' } 
                                    }
                                }}/>
                            <FormControlLabel control={<Checkbox />} label="No" 
                             componentsProps={{
                                typography: {
                                    style: { fontSize: '0.85rem' } 
                                }
                            }}/>
                        </Grid>
                        <Grid item xs={5}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>En caso afirmativo, indique la ubicación/proveedor</InputLabel>
                        </Grid>
                        <Grid item xs={7}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.representative_name} fullWidth inputProps={{style: { fontSize: '0.85rem' }}} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
                                Información de la parte responsable(no completar si el paciente es la parte responsable)
                            </Typography>
                        </Grid>
                        <Grid item xs={2.5}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>Relación con el paciente</InputLabel>
                        </Grid>
                        <Grid item xs={9.5}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.representative_relationship} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                        </Grid>
                        <Grid item xs={1}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>Apellido</InputLabel>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.representative_lastname} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                        </Grid>
                        <Grid item xs={1}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>Nombre</InputLabel>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.representative_firstname} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                        </Grid>
                        <Grid item xs={1.7}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>Segundo nombre</InputLabel>
                        </Grid>
                        <Grid item xs={2.3}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.representative_middlename} fullWidth inputProps={{style: { fontSize: '0.85rem' }}} />
                        </Grid>
                        <Grid item xs={3.1}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>Número de licencia de conducir</InputLabel>
                        </Grid>
                        <Grid item xs={2.9}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.representative_driver_license} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                        </Grid>
                        <Grid item xs={2.2}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>Fecha de nacimiento</InputLabel>
                        </Grid>
                        <Grid item xs={3.8}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.representative_dob} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                        </Grid>
                        <Grid item xs={1}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>Dirección</InputLabel>
                        </Grid>
                        <Grid item xs={5}>
                            <TextField id="standard-basic" label="" variant="standard" fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                        </Grid>
                        <Grid item xs={0.5}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>Apt.</InputLabel>
                        </Grid>
                        <Grid item xs={5.5}>
                            <TextField id="standard-basic" label="" variant="standard" fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                        </Grid>
                        <Grid item xs={1}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>Ciudad</InputLabel>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.representative_city} fullWidth inputProps={{style: { fontSize: '0.85rem' }}} />
                        </Grid>
                        <Grid item xs={1}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>Estado</InputLabel>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.representative_state} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                        </Grid>
                        <Grid item xs={1.5}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>Código Postal</InputLabel>
                        </Grid>
                        <Grid item xs={2.5}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.representative_zipcode} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                        </Grid>
                        <Grid item xs={2}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>Correo electrónico</InputLabel>
                        </Grid>
                        <Grid item xs={10}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.representative_email} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                        </Grid>
                        <Grid item xs={2}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>Teléfono residencial</InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.representative_homePhone} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                        </Grid>
                        <Grid item xs={1}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>Celular</InputLabel>
                        </Grid>
                        <Grid item xs={5}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.representative_cellPhone} fullWidth inputProps={{style: { fontSize: '0.85rem' }}} />
                        </Grid>
                        <Grid item xs={1}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>Trabajo</InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.representative_workPhone} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                        </Grid>
                        <Grid item xs={2.2}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>Extensión del trabajo</InputLabel>
                        </Grid>
                        <Grid item xs={4.8}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.representative_workPhoneext} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
                                Autorización para tratar
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom>
                                Yo (y/o el abajo firmante en nombre del paciente) doy mi consentimiento de forma voluntaria para permitir que los médicos y el personal de Optum proporcionen la evaluación o atención y los tratamientos, como paciente ambulatorio de forma continua y como paciente hospitalizado según sea necesario, que los médicos y el personal de Optum decidan que sean aconsejables y necesarios.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom>
                                Entiendo que, aunque los médicos de Optum revisan y supervisan la atención, la atención real puede ser proporcionada por proveedores de práctica avanzada (es decir, asistentes médicos, enfermeras practicantes, enfermeras parteras certificadas). Además, entiendo que los residentes, estudiantes de medicina, estudiantes para asistente médico, estudiantes para enfermera practicante, estudiantes de enfermería, estudiantes de farmacia u otros estudiantes para profesionales de la salud relacionados pueden ayudar en mi tratamiento.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom>
                                Se me informa que dicho tratamiento puede incluir examen médico, examen de rayos X, procedimientos de laboratorio, otros procedimientos en el consultorio, así como procedimientos para pacientes hospitalizados, según sea necesario.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom>
                                Entiendo que, en caso de firmar un poder duradero para atención médica u otra instrucción por anticipado, le proporcionaré una copia firmada a mi médico. Además, entiendo que notificaré a mi médico sobre cualquier cambio en la instrucción.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom>
                                Entiendo que se me informará sobre el curso de mi tratamiento. Además, soy libre de finalizar mi tratamiento con mi médico de Optum en cualquier momento.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
                                Cesión de beneficios
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom>
                                Por la presente cedo beneficios médicos o quirúrgicos, seguro privado y cualquier otro beneficio del plan de salud a Optum. Una copia de esta cesión se considera tan válida como el original.
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.full_name} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Formulario completado por (en letra de molde)</InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="standard-basic" label="" variant="standard" value={formattedDate} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Fecha</InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.full_name} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Firma</InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="standard-basic" label="" variant="standard" value={persona === "patient" ? "Self" : relation} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Relación con el paciente</InputLabel>
                        </Grid>
                        <Grid item xs={12} textAlign={'right'}>
                            <Typography variant="h6" gutterBottom>
                                2
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                <div style={{ height: '10px' }}></div>
                <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px' }} id="pageThree">
                    <Grid container spacing={1} alignItems="center" lineHeight={2.0}>
                        <Grid item xs={12}>
                            <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
                                Responsabilidad financiera
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom>
                                Entiendo que soy financieramente responsable de todos los cargos, los pague o no mi seguro, a menos que esté específicamente exento por el contrato de mi compañía de seguros con Optum.
                            </Typography>
                        </Grid>
                        <Grid item>
                            <InputLabel htmlFor="input1">Yo,</InputLabel>
                        </Grid>
                        <Grid item xs={5} textAlign={'center'}>
                        <TextField id="standard-basic" label="" variant="standard" value={formData.coverage1_policyHolderName ? formData.coverage1_policyHolderName : formData.coverage1_payorDisplay?formData.full_name: ""} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Nombre del paciente</InputLabel>
                        </Grid>
                        <Grid item>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>,certifico por la presente Nombre del paciente</InputLabel>
                        </Grid>
                        <Grid item>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>que soy elegible</InputLabel>
                        </Grid>
                        <Grid item>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>para</InputLabel>
                        </Grid>
                        <Grid item textAlign={'center'}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.coverage1_payorDisplay} fullWidth inputProps={{style: { fontSize: '0.85rem', minWidth: '250px' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Nombre del seguro</InputLabel>
                        </Grid>
                        <Grid item>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>,cuyos beneficios están en vigencia</InputLabel>
                        </Grid>
                        <Grid item textAlign={'center'}>
                            <TextField id="standard-basic" label="" variant="standard" value={formattedDate} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Nombre del seguro</InputLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom>
                                He elegido a <strong> Optum</strong> como mi proveedor médico. Entiendo que si lo anterior no es cierto, soy responsable de todos los cargos relacionados con los servicios que me presten. Además, si lo anterior no es cierto, acepto pagar la totalidad de todos los servicios recibidos dentro de los 30 días de la recepción de una factura de <strong>Optum</strong>.
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.full_name} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Firma del paciente o parte responsable</InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="standard-basic" label="" variant="standard" value={formattedDate} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Fecha</InputLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
                                Acuse de recibo del Aviso de Prácticas de Privacidad de Optum
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom>
                                Al firmar este documento, certifico que he recibido una copia del Aviso de prácticas de privacidad. Este aviso explica cómo este consultorio médico puede utilizar y divulgar mi información personal.
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.full_name} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Nombre del paciente (en letra de molde)</InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="standard-basic" label="" variant="standard" value={formattedDate} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Fecha</InputLabel>
                        </Grid>
                        <Grid item xs={7}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.full_name} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Firma</InputLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
                                Comunicaciones al número de teléfono celular
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom>
                                Al proporcionar mi número de teléfono celular a los médicos de Optum en este formulario, acepto recibir llamadas automáticas, mensajes pregrabados o mensajes de texto relacionados con mi atención de la salud de parte de Optum, sus afiliadas y sus respectivos médicos. Acepto recibir llamadas que incluyan el uso de un marcador automático, voces artificiales, voces pregrabadas o tecnología generada por IA a fin de recibir información importante más rápido sobre recordatorios de citas, resultados de pruebas, renovaciones de recetas, marketing y ayuda. Acepto que los Términos de uso de mensajes de texto se incluirán en el primer mensaje de texto que reciba. Confirmo y acepto que estos mensajes de texto, que se enviarán por medios sin cifrar, podrían contener información de salud protegida (PHI) y existe cierto riesgo de divulgación o interceptación de los mensajes. Es posible que se apliquen tarifas de mensajes de texto y de datos.
                                Puedo revocar o retirar este consentimiento en cualquier momento. Se puede retirar el consentimiento para mensajes de texto o llamadas automáticas respondiendo la palabra STOP a los mensajes de texto o llamando al
                                <strong>1-800-403-4160</strong>.
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.full_name} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Nombre del paciente (en letra de molde)</InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="standard-basic" label="" variant="standard" value={formattedDate} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Fecha</InputLabel>
                        </Grid>
                        <Grid item xs={7}>
                            <TextField id="standard-basic" label="" variant="standard" value={persona === "patient"? "" : representativeName} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Nombre del representante personal</InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="standard-basic" label="" variant="standard" value={persona === "patient" ? "Self" : relation} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Relación con el paciente/menor</InputLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
                                Aviso a los pacientes sobre Open Payments
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom>
                                La base de datos Open Payments es una herramienta federal utilizada para buscar pagos realizados por las compañías de medicamentos y dispositivos a médicos y hospitales universitarios. La puede encontrar en  <strong>openpaymentsdata.cms.gov</strong>.
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.full_name} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Firma del paciente o parte responsable</InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="standard-basic" label="" variant="standard" value={formattedDate} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Fecha</InputLabel>
                        </Grid>
                        <Grid item xs={12} textAlign={'right'}>
                            <Typography variant="h6" gutterBottom>
                                3
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                <div style={{ height: '10px' }}></div>
                <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px' }} id="pageFour">
                    <Grid container spacing={1} alignItems="center" lineHeight={2.0}>
                        <Grid item xs={12}>
                            <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
                                Reconocimiento de las Instrucciones por Anticipado sobre la Atención de la Salud
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom style={{ fontWeight: 'bold' }}>
                                Optum, de conformidad con la Ley de Autodeterminación del Paciente de 1990, garantiza el derecho del paciente a la autodeterminación al invitarlo a participar en las decisiones sobre su atención médica. Esto se logra a través de la planificación y la comunicación de sus deseos de tratamiento médico mediante un Formulario de Reconocimiento de las Instrucciones por Anticipado sobre la Atención de la Salud.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom style={{ fontWeight: 'bold' }}>
                                Mis iniciales junto a una de las siguientes declaraciones indican el estado actual de las instrucciones por anticipado.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <table>
                                <tr>
                                    <th style={{ width: '15%' ,fontSize: '0.85rem'}}>Iniciales</th>
                                    <th style={{ fontSize: '0.85rem'}}>Declaración</th>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td><Typography variant="body2" gutterBottom>
                                        Le he proporcionado una copia de mi Formulario de instrucciones por anticipado sobre la atención de la salud a Optum para que lo incluya en mi historial clínico.
                                    </Typography>
                                        <FormControlLabel control={<Checkbox />} label="Escaneado para EHR" 
                                        componentsProps={{
                                            typography: {
                                                style: { fontSize: '0.90rem' } 
                                            }
                                        }}/></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <Typography variant="body2" gutterBottom>
                                            Proporcionaré una copia de mis Instrucciones por anticipado sobre la atención de la salud a Optum.
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <Typography variant="body2" gutterBottom>
                                            No tengo Instrucciones por anticipado sobre la atención de la salud en este momento. Entiendo que es mi responsabilidad considerar este asunto con mi proveedor de atención primaria.
                                        </Typography>
                                    </td>
                                </tr>
                            </table>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom style={{ fontWeight: 'bold' }}>
                                Mi firma confirma que he informado a Optum de mi derecho a participar en la toma de decisiones sobre mi tratamiento médico mediante la firma de Instrucciones por Anticipado sobre la Atención de la Salud.
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.full_name} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Firma del paciente</InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.full_name} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Nombre en letra de molde</InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="standard-basic" label="" variant="standard" value={formattedDate} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Fecha</InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.witness_signature} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Firma del testigo</InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.witness_name} fullWidth inputProps={{style: { fontSize: '0.85rem' }}} />
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Nombre en letra de molde</InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="standard-basic" label="" variant="standard" value={formattedDate} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Fecha</InputLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom style={{ fontWeight: 'bold' }}>
                                <strong>Solo para uso administrativo:</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel control={<Checkbox />} label="Se le proporcionó información escrita y verbal al paciente. (paquete de Instrucciones por Anticipado sobre la Atención de la Salud)"  
                            componentsProps={{
                                                typography: {
                                                    style: { fontSize: '0.90rem' } 
                                                }
                                            }} />
                        </Grid>
                        <Grid item>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Comentarios: </InputLabel>
                        </Grid>
                        <Grid item xs={10}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.comments} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                        </Grid>
                        <Grid item xs={6}>
                            <img src={`${process.env.PUBLIC_URL}/Optum_logo.png`} alt="Optum Logo" style={{ width: '150px', height: '60px' }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom sx={{ fontSize: '0.875rem'}}>
                                Si usted es un tutor o un representante designado por un tribunal, debe proporcionar una copia de su autorización legal para representar al paciente.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom sx={{ fontSize: '0.875rem'}}>
                                La compañía no discrimina por motivos de raza, color, origen nacional, sexo, edad ni discapacidad en programas y actividades de salud. Ofrecemos servicios gratuitos para ayudarle a comunicarse con nosotros. Por ejemplo, cartas en otros idiomas o en letra grande. O bien, usted puede pedir un intérprete. Para pedir ayuda, llame al 1-800-403-4160, TTY 711. ATENCIÓN: Si habla español
                                (Spanish), hay servicios de asistencia de idiomas, sin cargo, a su disposición. Llame al 1-800-403-4160, TTY 711. 請注意 ：如果您說中文 (Chinese)，我們免費為您提供語言協助服務 。請致電 ：1-800-403-4160, TTY 711 。
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom sx={{ fontSize: '0.875rem'}}>
                                Optum es una marca registrada de Optum, Inc. en los Estados Unidos y en otras jurisdicciones. Todas las demás marcas comerciales son propiedad de sus respectivos titulares. Dado que mejoramos continuamente nuestros productos y servicios, Optum se reserva el derecho de cambiar las especificaciones sin previo aviso. Optum es una empresa que promueve la igualdad de oportunidades.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom sx={{ fontSize: '0.875rem'}}>
                                © 2024 Optum, Inc. Todos los derechos reservados. 24808 10/24
                            </Typography>
                        </Grid>
                        <Grid item xs={12} textAlign={'right'}>
                            <Typography variant="h6" gutterBottom>
                               4
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
};

export default PatientRegistrationSpa;