import React, { useState } from 'react';
import './customstyles.css'
import { aemLanguageOptions, preferredPronounsItems, genderItems, sexAssignedBirthItems, maritalStatusItems, ethnicityItems, raceItems } from './staticdata';
import { apicall_PatientData } from './savedata';
import { useAppSelector } from '../../app/hooks';
import { Grid, InputLabel, TextField, Typography } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

interface Props {
    // Define your component props here
}

const PatientRegistration: React.FC<Props> = () => {
    // Component logic goes here     

    const pdata: any = useAppSelector((state) => state.patient);
    const pInsurence: any = useAppSelector((state) => state.insurance);
    const persona = useAppSelector((state) => state.preferences.persona);
    const relation = useAppSelector((state) => state.preferences.relationship);
    const representativeName=useAppSelector((state)=>state.preferences.representativeFullName);
    /* Modal data start */
    const [formData, setFormData] = useState({
        patientId: pdata.patientId,
        first_name: pdata.first_name,
        last_name: pdata.last_name,
        full_name: pdata.full_name,
        timeStamp:pdata.timeStamp,
        demographics_sexAtBirth: pdata.demographics.sexAtBirth,
        demographics_genderIdentity: pdata.demographics.genderIdentity,
        demographics_preferredPronoun: pdata.demographics.preferredPronoun,
        PreferredName:pdata.PreferredName,
        demographics_religiousAffiliation: pdata.demographics.religiousAffiliation,
        demographics_maritalStatus: pdata.demographics.maritalStatus,
        demographics_race: pdata.demographics.race,
        demographics_ethnicity: pdata.demographics.ethnicity,
        middle_name: pdata.middle_name,
        other_name: pdata.other_name,
        other_GI: pdata.other_GI,
        other_SAbirth: pdata.other_SAbirth,
        emergencyContact_address: pdata.emergencyContact_address,
        emergencyContact_apt: pdata.emergencyContact_apt,
        emergencyContact_city: pdata.emergencyContact_city,
        emergencyContact_state: pdata.emergencyContact_state,
        emergencyContact_zip: pdata.emergencyContact_zip,
        emergencyContact_workPhoneext: pdata.emergencyContact_workPhoneext,
        PrintName: pdata.PrintName,
        relationship: pdata.relationship,
        filled_date: pdata.filled_date,
        //values
        demographics_sexAtBirthValue: pdata.demographics.sexAtBirthValue,
        demographics_genderIdentityValue: pdata.demographics.genderIdentityValue,
        demographics_preferredPronounValue: pdata.demographics.preferredPronounValue,
        demographics_religiousAffiliationValue: pdata.demographics.religiousAffiliationValue,
        demographics_maritalStatusValue: pdata.demographics.maritalStatusValue,
        demographics_raceValue: pdata.demographics.raceValue,
        demographics_ethnicityValue: pdata.demographics.ethnicityValue,
        email: pdata.email,
        consentToEmail: pdata.consentToEmail,
        consentToText: pdata.consentToText,
        homePhone: pdata.homePhone,
        workPhone: pdata.workPhone,
        workPhoneExtn: pdata.workPhoneExtn,
        cellPhone: pdata.cellPhone,
        preferredPhoneType: pdata.preferredPhoneType,
        preferredPhoneTypeCode: pdata.preferredPhoneTypeCode,
        address1: pdata.address.address1,
        address2: pdata.address.address2,
        city: pdata.address.city,
        state: pdata.address.state,
        zipcode: pdata.address.zipcode,
        dob: pdata.dob,
        isAdultPatient: pdata.isAdultPatient,  // true if patient has valid DOB and is over 18 years old
        cdoLanguageKey: pdata.cdoLanguageKey,   // this is the CDO specific language key from AEM for the current CDO.  This is not the ISO language code
        cdoLanguageName: pdata.cdoLanguageName,  // this is the CDO specific display name for the language in the current selected language
        photoid_front: pdata.photoid_front,
        photoid_back: pdata.photoid_back,
        emergencyContact_first_name: pdata.emergencyContact.first_name,
        emergencyContact_last_name: pdata.emergencyContact.last_name,
        emergencyContact_full_name: pdata.emergencyContact.full_name,
        emergencyContact_relationship: pdata.emergencyContact.relationship,
        emergencyContact_email: pdata.emergencyContact.email,
        emergencyContact_homePhone: pdata.emergencyContact.homePhone,
        emergencyContact_workPhone: pdata.emergencyContact.workPhone,
        emergencyContact_cellPhone: pdata.emergencyContact.cellPhone,
        representative_name: pdata.representative.name,
        representative_firstname: pdata.representative_firstname,
        representative_middlename: pdata.representative_middlename,
        representative_lastname: pdata.representative_lastname,
        representative_driver_license: pdata.representative_driver_license,
        representative_email: pdata.representative_email,
        representative_cellPhone: pdata.representative_cellPhone,
        representative_workPhone: pdata.representative_workPhone,
        representative_workPhoneext: pdata.representative_workPhoneext,
        representative_relationship: pdata.representative_relationship,
        representative_dob: pdata.representative.dob,
        representative_address1: pdata.representative.address.address1,
        representative_address2: pdata.representative.address.address2,
        representative_city: pdata.representative.address.city,
        representative_state: pdata.representative.address.state,
        representative_zipcode: pdata.representative.address.zipcode,
        representative_homePhone: pdata.representative.homePhone,
        representative_photoid_front: pdata.representative.photoid_front,
        representative_photoid_back: pdata.representative.photoid_back,
        advancedDirective_selection: pdata.advancedDirective.selection,
        accountBalance: pdata.accountBalance,
        representative_relation: pdata.representative_relation,
        representative_fullname: pdata.representative_fullname,
        comments: pdata.comments,
        witness_name: pdata.witness_name,
        witness_signature: pdata.witness_signature,


        coverage1_fhirInsuranceId: pInsurence.coverage1.fhirInsuranceId,
        coverage1_payorDisplay: pInsurence.coverage1.payorDisplay,
        coverage1_memberId: pInsurence.coverage1.memberId,
        coverage1_groupNumber: pInsurence.coverage1.groupNumber,
        coverage1_policyHolderName: pInsurence.coverage1.policyHolderName,
        coverage1_policyHolderDOB: pInsurence.coverage1.policyHolderDOB,
        coverage1_policyHolderRelationToPatientCode: pInsurence.coverage1.policyHolderRelationToPatientCode,
        coverage1_policyHolderRelationToPatientValue: pInsurence.coverage1.policyHolderRelationToPatientValue,
        coverage1_insuranceCardFrontImage: pInsurence.coverage1.insuranceCardFrontImage,
        coverage1_insuranceCardBackImage: pInsurence.coverage1.insuranceCardBackImage,
        coverage1_order: pInsurence.coverage1.order,

        coverage2_fhirInsuranceId: pInsurence.coverage2.fhirInsuranceId,
        coverage2_payorDisplay: pInsurence.coverage2.payorDisplay,
        coverage2_memberId: pInsurence.coverage2.memberId,
        coverage2_groupNumber: pInsurence.coverage2.groupNumber,
        coverage2_policyHolderName: pInsurence.coverage2.policyHolderName,
        coverage2_policyHolderDOB: pInsurence.coverage1.policyHolderDOB,
        coverage2_policyHolderRelationToPatientCode: pInsurence.coverage2.policyHolderRelationToPatientCode,
        coverage2_policyHolderRelationToPatientValue: pInsurence.coverage2.policyHolderRelationToPatientValue,
        coverage2_insuranceCardFrontImage: pInsurence.coverage2.insuranceCardFrontImage,
        coverage2_insuranceCardBackImage: pInsurence.coverage2.insuranceCardBackImage,


        hasSelfCoverage: pInsurence.hasSelfCoverage,
        hasCoverage1: pInsurence.hasCoverage1,
        hasCoverage2: pInsurence.hasCoverage2,
        insuranceProviderPayors: []

    });
    /* Modal data end */

    /* Change and Select events start */
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };
    const [formState, setFormState] = useState({
        demographics_genderIdentity: formData.demographics_genderIdentity,
        demographics_sexAtBirth: formData.demographics_sexAtBirth,
        demographics_maritalStatus: formData.demographics_maritalStatus,
        demographics_ethnicity: formData.demographics_ethnicity,
        demographics_race: formData.demographics_race,
    });
    const [formInfo, setFormInfo] = useState({
        cdoLanguageKey: formData.cdoLanguageKey,
        cdoLanguageName: formData.cdoLanguageName,
        emergencyContact_relationship: formData.emergencyContact_relationship,
        demographics_preferredPronoun: formData.demographics_preferredPronoun,
        demographics_preferredPronounValue: formData.demographics_preferredPronounValue,
        coverage1_payorDisplay: formData.coverage1_payorDisplay,
    });
    const handleChange = (event) => {
        const { name, value } = event.target;
        let updatedFormInfo = { ...formInfo };
        if (name === 'preferredPronoun') {
            const selectedPronoun = preferredPronounsItems.find(item => item.Key === value);
            updatedFormInfo = {
                ...updatedFormInfo,
                demographics_preferredPronoun: value,
                demographics_preferredPronounValue: selectedPronoun ? selectedPronoun.Value : ''
            };
        } else if (name === 'cdoLanguage') {
            const selectedLanguage = aemLanguageOptions.find(item => item.Key === value);
            updatedFormInfo = {
                ...updatedFormInfo,
                cdoLanguageKey: value,
                cdoLanguageName: selectedLanguage ? selectedLanguage.Value : ''
            };
        }
        setFormInfo(updatedFormInfo);
        formData.cdoLanguageKey = updatedFormInfo.cdoLanguageKey;
        formData.cdoLanguageName = updatedFormInfo.cdoLanguageName;
        formData.demographics_preferredPronoun = updatedFormInfo.demographics_preferredPronoun;
        formData.demographics_preferredPronounValue = updatedFormInfo.demographics_preferredPronounValue
    };
    const updation = (field, key, value) => {
        formData[field] = key
        formData[field + "Value"] = value
    }
    const handleCheckChange = (event) => {
        const { name, value } = event.target;
        // console.log(value)
        const arrValues = value.split(',');
        setFormState(() => ({
            ...formState,
            [name]: arrValues[0],
        }));
        updation(name, arrValues[0], arrValues[1]);
    };
    const today = new Date();
    const formattedDate = `${String(today.getMonth() + 1).padStart(2, '0')}/${String(today.getDate()).padStart(2, '0')}/${today.getFullYear()}`;
    const handleSelectChange = (event) => {
        const { name, value } = event.target;
        if (name == "coverage1_payorDisplay") {
            setFormInfo({
                ...formInfo,
                [name]: value
            });
        }
        else {
            setFormInfo({
                ...formInfo,
                [name]: value
            });
        }
    };
    formData.emergencyContact_relationship = formInfo.emergencyContact_relationship;
    formData.coverage1_payorDisplay = formInfo.coverage1_payorDisplay;
    /* Change and Seelct events end */
    /*Save Modal construction start*/
    apicall_PatientData(formData);
    /*Save Modal construction end*/
    return (
        <>
            <div style={{ padding: '15px' }} id='mainDiv'>
                <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px' }} id="pageOne">
                    <form>
                        <Grid container spacing={2} alignItems="center" lineHeight={2.5}>
                            <Grid item xs={6}>
                                <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold' }}>
                                    Patient Registration
                                </Typography>
                            </Grid>
                            <Grid item xs={6} textAlign='right'>
                                <img src={`${process.env.PUBLIC_URL}/Optum_logo.png`} alt="Optum Logo" style={{ width: '150px', height: '60px' }} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} alignItems="left" lineHeight={2.5}>
                            <Grid item xs={6}>
                            <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
                                    Patient information (please print)
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} alignItems="center" lineHeight={2.5}>
                            <Grid item xs={1.3}>
                                <InputLabel htmlFor="input1">Last Name</InputLabel>
                            </Grid>
                            <Grid item xs={2.5}>
                                <TextField id="standard-basic" label="" variant="standard" value={formData.last_name} fullWidth />
                            </Grid>
                            <Grid item xs={1.3}>
                                <InputLabel htmlFor="input1">First Name</InputLabel>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField id="standard-basic" label="" variant="standard" value={formData.first_name} fullWidth />
                            </Grid>
                            <Grid item xs={1.5} textAlign='right'>
                                <InputLabel htmlFor="input1">Middle Name</InputLabel>
                            </Grid>
                            <Grid item xs={2.3}>
                                <TextField id="standard-basic" label="" variant="standard" value={formData.middle_name} fullWidth />
                            </Grid>
                            <Grid item xs={2}>
                                <InputLabel htmlFor="input1">Preferred Name</InputLabel>
                            </Grid>
                            <Grid item xs={10}>
                                <TextField id="standard-basic" label="" variant="standard" value={formData.PreferredName} fullWidth />
                            </Grid>
                            <Grid item xs={3.5}>
                                <InputLabel htmlFor="input1">Other name(s) you are also known as</InputLabel>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField id="standard-basic" label="" variant="standard" value={formData.other_name} fullWidth />
                            </Grid>
                            <Grid item xs={1} textAlign='right'>
                                <InputLabel htmlFor="input1">DOB</InputLabel>
                            </Grid>
                            <Grid item xs={4.2}>
                                <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.dob}/>
                            </Grid>
                            <Grid item xs={2}>
                                <InputLabel htmlFor="input1">Gender identity</InputLabel>
                            </Grid>
                            <Grid item xs={10}>
                                <FormControlLabel control={<Checkbox checked={genderItems[3].Value === formData.demographics_genderIdentity}/>} label="Man" />
                                <FormControlLabel control={<Checkbox checked={genderItems[0].Value === formData.demographics_genderIdentity} />} label="Woman" />
                                <FormControlLabel control={<Checkbox />} label="Nonbinary" />
                                <FormControlLabel control={<Checkbox />} label="Genderqueer" />
                                <FormControlLabel control={<Checkbox checked={genderItems[5].Value === formData.demographics_genderIdentity} />} label="Prefer not to disclose" />
                                <FormControlLabel control={<><Checkbox checked={formData.demographics_genderIdentity && (['Male', 'Female','Prefer Not to Disclose'].indexOf(formData.demographics_genderIdentity) === -1)}  /><InputLabel htmlFor="input1">Other</InputLabel><TextField id="standard-basic" label="" variant="standard" value={(['Male', 'Female','Prefer Not to Disclose','Other'].indexOf(formData.demographics_genderIdentity) === -1) ? formData.demographics_genderIdentityValue : ''}  style={{marginLeft:'10px',width: '350px'}}/></>} label="" />
                            </Grid>
                            <Grid item xs={2}>
                                <InputLabel htmlFor="input1">Sex assigned at birth</InputLabel>
                            </Grid>
                            <Grid item xs={10}>
                                <FormControlLabel control={<Checkbox checked={sexAssignedBirthItems[0].Value === formData.demographics_sexAtBirthValue}/>} label="Man" />
                                <FormControlLabel control={<Checkbox checked={sexAssignedBirthItems[1].Value === formData.demographics_sexAtBirthValue} />} label="Woman" />
                                <FormControlLabel control={<Checkbox />} label="Nonbinary" />
                                <FormControlLabel control={<Checkbox />} label="Genderqueer" />
                                <FormControlLabel control={<Checkbox />} label="Prefer not to disclose" />
                                <FormControlLabel control={<><Checkbox checked={formData.demographics_sexAtBirthValue && (['Male', 'Female'].indexOf(formData.demographics_sexAtBirthValue)=== -1)} /><InputLabel htmlFor="input1">Other</InputLabel><TextField id="standard-basic" label="" variant="standard" value={(['Male', 'Female'].indexOf(formData.demographics_sexAtBirthValue)=== -1) ? formData.demographics_sexAtBirthValue: ''} style={{marginLeft:'10px',width: '350px'}}/></>} label="" />
                            </Grid>
                            <Grid item xs={2} >
                                <InputLabel htmlFor="input1">Relationship status</InputLabel>
                            </Grid>
                            <Grid item xs={7}>
                                <FormControlLabel control={<Checkbox checked={maritalStatusItems[0]["Key"] === formData.demographics_maritalStatus}/>} label="Single" />
                                <FormControlLabel control={<Checkbox checked={'In a relationship' === formData.demographics_maritalStatus}/>} label="In a relationship" />
                                <FormControlLabel control={<Checkbox checked={maritalStatusItems[1]["Key"] === formData.demographics_maritalStatus}/>} label="Married" />
                                <FormControlLabel control={<Checkbox checked={maritalStatusItems[2]["Key"] === formData.demographics_maritalStatus}/>} label="Widowed" />
                                <FormControlLabel control={<Checkbox checked={maritalStatusItems[3]["Key"] === formData.demographics_maritalStatus}/>} label="Separated" />
                                <FormControlLabel control={<Checkbox checked={maritalStatusItems[4]["Key"] === formData.demographics_maritalStatus}/>} label="Divorced" />
                            </Grid>
                            <Grid container style={{ border: "1px solid black", padding: '15px', margin: '10px' }} spacing={1} lineHeight={2.5}>
                                <Grid item xs={12} >
                                <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
                                        Required Information
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>Address</InputLabel>
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.address1}/>
                                </Grid>
                                <Grid item xs={1} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>Apt.</InputLabel>
                                </Grid>
                                <Grid item xs={4} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.address2}/>
                                </Grid>
                                <Grid item xs={1} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>State</InputLabel>
                                </Grid>
                                <Grid item xs={2} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.state}/>
                                </Grid>
                                <Grid item xs={1} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>City</InputLabel>
                                </Grid>
                                <Grid item xs={2} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.city}/>
                                </Grid>
                                <Grid item xs={1} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>Zip</InputLabel>
                                </Grid>
                                <Grid item xs={2} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.zipcode}/>
                                </Grid>
                                <Grid item xs={12} >
                                <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
                                        Phone numbers (please check box of your preferred contact number)
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} >
                                    <FormControlLabel control={<Checkbox checked={'homePhone' === formData.preferredPhoneType}/>} label="Home" />
                                </Grid>
                                <Grid item xs={5} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.homePhone.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}/>
                                </Grid>
                                <Grid item xs={1} >
                                    <FormControlLabel control={<Checkbox checked={'cellPhone' === formData.preferredPhoneType} />} label="Cell" />
                                </Grid>
                                <Grid item xs={5} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.cellPhone}/>
                                </Grid>
                                <Grid item xs={1} >
                                    <FormControlLabel control={<Checkbox checked={'workPhone' === formData.preferredPhoneType} />} label="Work" />
                                </Grid>
                                <Grid item xs={5} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.workPhone.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}/>
                                </Grid>
                                <Grid item xs={1.2} >
                                    <FormControlLabel control={<Checkbox checked={'workPhoneExtn' === formData.preferredPhoneType} />} label="Work.Ext" />
                                </Grid>
                                <Grid item xs={4} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.workPhoneExtn}/>
                                </Grid>
                                <Grid item xs={1} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>Email</InputLabel>
                                </Grid>
                                <Grid item xs={8} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.email}/>
                                </Grid>
                                <Grid item xs={12} >
                                <Typography variant="body2" gutterBottom >
                                        By providing your email above, you acknowledge the emails may contain your Protected Health
                                        Information and will be sent unencrypted. There is a risk of interception or disclosure of the contents
                                        of these emails.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container style={{ margin: '5px' }} spacing={1} lineHeight={2.5}>
                                <Grid item xs={12} >
                                <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
                                        Emergency contact
                                    </Typography>
                                </Grid>
                                <Grid item xs={1.5} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>Last Name</InputLabel>
                                </Grid>
                                <Grid item xs={2.6} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.emergencyContact_last_name}/>
                                </Grid>
                                <Grid item xs={1} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>First</InputLabel>
                                </Grid>
                                <Grid item xs={2.6} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.emergencyContact_first_name}/>
                                </Grid>
                                <Grid item xs={1.4} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>Relationship</InputLabel>
                                </Grid>
                                <Grid item xs={2.8} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.emergencyContact_relationship}/>
                                </Grid>
                                <Grid item xs={1} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>Address</InputLabel>
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.emergencyContact_address}/>
                                </Grid>
                                <Grid item xs={0.5} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>Apt.</InputLabel>
                                </Grid>
                                <Grid item xs={4.5} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth />
                                </Grid>
                                <Grid item xs={1} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>City</InputLabel>
                                </Grid>
                                <Grid item xs={3} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.emergencyContact_city}/>
                                </Grid>
                                <Grid item xs={1} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>State</InputLabel>
                                </Grid>
                                <Grid item xs={3} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.emergencyContact_state}/>
                                </Grid>
                                <Grid item xs={1} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>Zip</InputLabel>
                                </Grid>
                                <Grid item xs={3} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.emergencyContact_zip}/>
                                </Grid>
                                <Grid item xs={1.5} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>Home phone</InputLabel>
                                </Grid>
                                <Grid item xs={4.5} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.emergencyContact_homePhone}/>
                                </Grid>
                                <Grid item xs={1} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>Cell</InputLabel>
                                </Grid>
                                <Grid item xs={5} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.emergencyContact_cellPhone.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}/>
                                </Grid>
                                <Grid item xs={1} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>Work</InputLabel>
                                </Grid>
                                <Grid item xs={5} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.emergencyContact_workPhone}/>
                                </Grid>
                                <Grid item xs={1} >
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>Work ext.</InputLabel>
                                </Grid>
                                <Grid item xs={5} >
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.emergencyContact_workPhoneext}/>
                                </Grid>
                            </Grid>
                            <Grid container style={{ border: "1px solid black", padding: '15px', margin: '10px' }} spacing={1} lineHeight={2.5}>
                                <Grid item xs={12} >
                                <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
                                        Required Information
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} style={{ marginTop: '8px' }}>
                                    <InputLabel htmlFor="input1">Ethnicity (select one)</InputLabel>
                                </Grid>
                                <Grid item xs={10}>
                                    <FormControlLabel control={<Checkbox checked={ethnicityItems[0]["Key"] === formData.demographics_ethnicity}/>} label="Hispanic or Latino or Spanish origin" />
                                    <FormControlLabel control={<Checkbox checked={ethnicityItems[2]["Key"] === formData.demographics_ethnicity}/>} label="Prefer not to disclose" />
                                    <FormControlLabel control={<Checkbox checked={ethnicityItems[1]["Key"] === formData.demographics_ethnicity}/>} label="Not Hispanic, Latino or Spanish origin" />
                                </Grid>
                                <Grid item xs={2} style={{ marginTop: '8px' }}>
                                    <InputLabel htmlFor="input1">Race (select one)</InputLabel>
                                </Grid>
                                <Grid item xs={10}>
                                    <FormControlLabel control={<Checkbox checked={raceItems[0]["Key"] === formData.demographics_race}/>} label="American Indian/Alaska native" />
                                    <FormControlLabel control={<Checkbox checked={raceItems[1]["Key"] === formData.demographics_race}/>} label="Asian" />
                                    <FormControlLabel control={<Checkbox checked={raceItems[2]["Key"] === formData.demographics_race}/>} label="Black or African American" />
                                    <FormControlLabel control={<Checkbox checked={raceItems[4]["Key"] === formData.demographics_race}/>} label="White or Caucasian" />
                                    <FormControlLabel control={<Checkbox checked={raceItems[3]["Key"] === formData.demographics_race}/>} label="Native Hawaiian or Pacific Islander" />
                                    <FormControlLabel control={<Checkbox checked={raceItems[5]["Key"] === formData.demographics_race}/>} label="Prefer not to disclose" />
                                </Grid>
                                <Grid item xs={2}>
                                    <InputLabel htmlFor="input1" className='lblmarginTop'>Preferred language</InputLabel>
                                </Grid>
                                <Grid item xs={10}>
                                    <TextField id="standard-basic" label="" variant="standard" fullWidth value={formData.cdoLanguageName}/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} textAlign={'right'}>
                                <Typography variant="h6" gutterBottom>
                                    1
                                </Typography>
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <div style={{ height: '10px' }}></div>
                <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px' }} id="pageTwo">
                    <Grid container spacing={0.8} alignItems="center" lineHeight={0}>
                        <Grid item xs={1.5}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>Occupation</InputLabel>
                        </Grid>
                        <Grid item xs={10.5}>
                            <TextField id="standard-basic" label="" variant="standard" value={''} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>Have you ever been a patient in any Optum facility before?</InputLabel>
                        </Grid>
                        <Grid item xs={5}>
                            <FormControlLabel control={<Checkbox />} label="Yes" 
                               componentsProps={{
                                            typography: {
                                                style: { fontSize: '0.85rem' } 
                                            }
                                }}/>
                            <FormControlLabel control={<Checkbox />} label="No" 
                                componentsProps={{
                                    typography: {
                                        style: { fontSize: '0.85rem' } 
                                    }
                                }}/>
                        </Grid>
                        <Grid item xs={3.5}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>If yes, state the location/provider</InputLabel>
                        </Grid>
                        <Grid item xs={8.5}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.representative_name} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                        </Grid>
                        <Grid item xs={12}>
                        <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
                                Responsible party information (do not complete if patient is responsible party)
                            </Typography>
                        </Grid>
                        <Grid item xs={2.5}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>Relationship to patient</InputLabel>
                        </Grid>
                        <Grid item xs={9.5}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.representative_relationship} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                        </Grid>
                        <Grid item xs={1.3}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>Last name</InputLabel>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.representative_lastname} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                        </Grid>
                        <Grid item xs={0.7}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>First</InputLabel>
                        </Grid>
                        <Grid item xs={3.0}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.representative_firstname} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                        </Grid>
                        <Grid item xs={1}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>Middle</InputLabel>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.representative_middlename} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                        </Grid>
                        <Grid item xs={2.5}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>Driver’s license number</InputLabel>
                        </Grid>
                        <Grid item xs={3.5}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.representative_driver_license} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                        </Grid>
                        <Grid item xs={0.7}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>DOB</InputLabel>
                        </Grid>
                        <Grid item xs={5.3}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.representative_dob} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                        </Grid>
                        <Grid item xs={1}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>Address</InputLabel>
                        </Grid>
                        <Grid item xs={5}>
                            <TextField id="standard-basic" label="" variant="standard" fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                        </Grid>
                        <Grid item xs={0.5}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>Apt.</InputLabel>
                        </Grid>
                        <Grid item xs={5.5}>
                            <TextField id="standard-basic" label="" variant="standard" fullWidth inputProps={{style: { fontSize: '0.85rem' }}} />
                        </Grid>
                        <Grid item xs={0.5}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>City</InputLabel>
                        </Grid>
                        <Grid item xs={3.3}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.representative_city} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                        </Grid>
                        <Grid item xs={0.8}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>State</InputLabel>
                        </Grid>
                        <Grid item xs={3.3}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.representative_state} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                        </Grid>
                        <Grid item xs={0.5}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>ZIP</InputLabel>
                        </Grid>
                        <Grid item xs={3.3}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.representative_zipcode} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                        </Grid>
                        <Grid item xs={1}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>Email</InputLabel>
                        </Grid>
                        <Grid item xs={10}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.representative_email} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                        </Grid>
                        <Grid item xs={1.5}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>Home phone</InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.representative_homePhone} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                        </Grid>
                        <Grid item xs={0.7}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>Cell</InputLabel>
                        </Grid>
                        <Grid item xs={5.3}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.representative_cellPhone} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                        </Grid>
                        <Grid item xs={0.7}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>Work</InputLabel>
                        </Grid>
                        <Grid item xs={5.3}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.representative_workPhone} fullWidth inputProps={{style: { fontSize: '0.85rem' }}} />
                        </Grid>
                        <Grid item xs={1.0}>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.85rem' }}>Work ext.</InputLabel>
                        </Grid>
                        <Grid item xs={5.0}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.representative_workPhoneext} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                        </Grid>
                        <Grid item xs={12}>
                        <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
                                Authorization to treat
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom>
                                I (and/or the undersigned on behalf of the patient) voluntarily consent to allow Optum physicians and
                                staff to provide such evaluation and/or care and treatments as an outpatient on a continuing basis and as
                                an inpatient as necessary, as Optum physicians and staff may decide is advisable and necessary.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom>
                                I understand that although care is reviewed and supervised by Optum physicians, actual care may be
                                rendered by physician extenders (i.e., physician assistants, nurse practitioners, certified nurse midwife).
                                I further understand that residents, medical students, physician assistant students, nurse practitioner
                                students, nursing students, pharmacy students or other allied health professional students may assist in
                                my treatment.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom>
                                I am advised that such treatment may include physical examination, X-ray examination, laboratory
                                procedures, other office procedures as well as inpatient procedures as required.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom>
                                I understand that should I execute a Durable Power of Attorney for Health Care or other Advance
                                Directive, I will provide an executed copy to my physician. I further understand that I will notify my
                                physician of any changes in the Directive.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom>
                                I understand that I will be informed about the course of my treatment. Also, I am free to terminate my
                                treatment with my Optum physician at anytime.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                        <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
                                Assignment of benefits
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom>
                                I hereby assign medical and/or surgical benefits, private insurance, and any other health plan benefits to
                                Optum. A copy of this assignment is considered valid as the original.
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.full_name} fullWidth inputProps={{style: { fontSize: '0.90rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Form completed by (print)</InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="standard-basic" label="" variant="standard" value={formattedDate} fullWidth inputProps={{style: { fontSize: '0.90rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Date</InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.full_name} fullWidth inputProps={{style: { fontSize: '0.90rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Signature</InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField id="standard-basic" label="" variant="standard" value={persona === "patient" ? "Self" : relation} fullWidth inputProps={{style: { fontSize: '0.90rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Relationship to patient</InputLabel>
                        </Grid>
                        <Grid item xs={12} textAlign={'right'}>
                            <Typography variant="h6" gutterBottom>
                                2
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                <div style={{ height: '10px' }}></div>
                <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px' }} id="pageThree">
                    <Grid container spacing={1} alignItems="center" lineHeight={2.0}>
                        <Grid item xs={12}>
                        <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
                                Financial responsibility
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom>
                                I understand that I am financially responsible for all charges, whether or not paid by my insurance,
                                unless specifically exempted by my insurance company’s contract with Optum.
                            </Typography>
                        </Grid>
                        <Grid item>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>I,</InputLabel>
                        </Grid>
                        <Grid item xs={5} textAlign={'center'}>
                        <TextField id="standard-basic" label="" variant="standard" value={formData.coverage1_policyHolderName  ? formData.coverage1_policyHolderName : formData.coverage1_payorDisplay?formData.full_name: ""} fullWidth inputProps={{style: { fontSize: '0.85rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Name of patient</InputLabel>
                        </Grid>
                        <Grid item>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>,hereby certify that I am eligible</InputLabel>
                        </Grid>
                        <Grid item>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>for</InputLabel>
                        </Grid>
                        <Grid item textAlign={'center'}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.coverage1_payorDisplay} fullWidth inputProps={{style: { fontSize: '0.85rem', minWidth: '250px' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Insurance name</InputLabel>
                        </Grid>
                        <Grid item>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>, benefits effective</InputLabel>
                        </Grid>
                        <Grid item textAlign={'center'}>
                            <TextField id="standard-basic" label="" variant="standard" value={formattedDate} fullWidth inputProps={{style: { fontSize: '0.90rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Effective date</InputLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom>
                                I have chosen <strong>Optum</strong> to be my medical provider. I understand that if the above is not true, I am
                                responsible for all charges related to services provided to me. Also, if the above is not true, I agree to pay in
                                full for all services received within 30 days of receiving a bill from <strong>Optum</strong>.
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.full_name} fullWidth inputProps={{style: { fontSize: '0.90rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Signature of patient or responsible party</InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="standard-basic" label="" variant="standard" value={formattedDate} fullWidth inputProps={{style: { fontSize: '0.90rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Date</InputLabel>
                        </Grid>
                        <Grid item xs={12}>
                        <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
                                Acknowledgment of receipt of Optum Notice of Privacy Practices
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom>
                                By signing this document, I acknowledge that I have been provided a copy of the Notice of Privacy Practices.
                                This notice explains how my personal information can be used and disclosed by this medical office.
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.full_name} fullWidth inputProps={{style: { fontSize: '0.90rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Printed name</InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="standard-basic" label="" variant="standard" value={formattedDate} fullWidth inputProps={{style: { fontSize: '0.90rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Date</InputLabel>
                        </Grid>
                        <Grid item xs={7}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.full_name} fullWidth inputProps={{style: { fontSize: '0.90rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Signature</InputLabel>
                        </Grid>
                        <Grid item xs={12}>
                        <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
                                Cellular telephone number communications
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom>
                            By providing my cellular telephone number to Optum physicians on this form, I agree to receive automated calls, prerecorded messages and/or text messages related to my health care from Optum, its affiliates and their respective physicians. I agree to receive calls including use of an auto dialer, artificial voice, prerecorded voice, and/or AI-generated technology to receive important information faster regarding appointment reminders, test results, prescription renewals, marketing, and outreach. I acknowledge that the Texting Terms of Use will be included in the first text message I receive. I acknowledge and agree that the text messages, which will be sent via unencrypted means, may contain Protected Health Information (PHI) and there is some risk of disclosure or interception of these messages. Message and data rates may apply. I may revoke or withdraw this consent at any time. Withdrawal of consent for text messages or automated calls can be made by replying STOP to the text messages or  calling <strong>1-800-403-4160.</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.full_name} fullWidth inputProps={{style: { fontSize: '0.90rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Signature of patient or personal representative</InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="standard-basic" label="" variant="standard" value={formattedDate} fullWidth inputProps={{style: { fontSize: '0.90rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Date</InputLabel>
                        </Grid>
                        <Grid item xs={7}>
                            <TextField id="standard-basic" label="" variant="standard" value={persona === "patient"? "" : representativeName} fullWidth inputProps={{style: { fontSize: '0.90rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Personal representative’s name</InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="standard-basic" label="" variant="standard" value={persona === "patient" ? "Self" : relation}  fullWidth inputProps={{style: { fontSize: '0.90rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Relationship to patient/minor</InputLabel>
                        </Grid>
                        <Grid item xs={12}>
                        <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
                                Open Payments notice to patients
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom>
                                The Open Payments database is a federal tool used to search payments made by drug and device
                                companies to physicians and teaching hospitals. It can be found at <strong>openpaymentsdata.cms.gov</strong>.
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.full_name} fullWidth inputProps={{style: { fontSize: '0.90rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Patient or representative signature</InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="standard-basic" label="" variant="standard" value={formattedDate} fullWidth inputProps={{style: { fontSize: '0.90rem' }}} />
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Date</InputLabel>
                        </Grid>
                        <Grid item xs={12} textAlign={'right'}>
                            <Typography variant="h6" gutterBottom>
                                3
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                <div style={{ height: '10px' }}></div>
                <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px' }} id="pageFour">
                    <Grid container spacing={1} alignItems="center" lineHeight={2.0}>
                        <Grid item xs={12}>
                        <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
                                Advance health care directive acknowledgment
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom style={{ fontWeight: 'bold' }}>
                                Optum, in compliance with the Patient Self Determination Act of 1990, ensures a patient’s right to
                                    self-determination by inviting patients to participate in decisions about their health care. This is
                                    accomplished through the planning and communication of their medical treatment wishes through an
                                    Advance Health Care Directive Acknowledgment Form.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom style={{ fontWeight: 'bold' }}>
                                My initials next to one of the following statements indicates my current Advance Directive status.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <table>
                                <tr>
                                    <th style={{ width: '15%' ,fontSize: '0.85rem'}}>Initials</th>
                                    <th style={{ fontSize: '0.85rem'}}>Statement</th>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td><Typography variant="body2" gutterBottom >
                                        I have provided a copy of my Advance Health Care Directive Form to Optum to
                                        be placed in my chart.
                                    </Typography>
                                        <FormControlLabel control={<Checkbox />} sx={{ fontSize: '0.875rem'}} label="Scanned to EHR" 
                                         componentsProps={{
                                            typography: {
                                                style: { fontSize: '0.90rem' } 
                                            }
                                        }}/></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                    <Typography variant="body2" gutterBottom >
                                            I will provide a copy of my Advance Health Care Directive to Optum.
                                        </Typography>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <Typography variant="body2" gutterBottom >
                                            I do not have an Advance Health Care Directive at this time. I understand that it is
                                            my responsibility to discuss this matter with my primary care provider.
                                        </Typography>
                                    </td>
                                </tr>
                            </table>
                        </Grid>
                        <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom style={{ fontWeight: 'bold' }}>
                                My signature acknowledges that I have informed Optum of my right to participate in making decisions
                                    about my medical treatment by executing an Advance Health Care Directive.
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.full_name} fullWidth inputProps={{style: { fontSize: '0.90rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Patient signature</InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.full_name} fullWidth inputProps={{style: { fontSize: '0.90rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Printed name</InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="standard-basic" label="" variant="standard" value={formattedDate} fullWidth inputProps={{style: { fontSize: '0.90rem' }}}/>
                            <InputLabel htmlFor="input1"style={{ fontSize: '0.90rem' }}>Date</InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.witness_signature} fullWidth inputProps={{style: { fontSize: '0.90rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Witness signature</InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.witness_name} fullWidth inputProps={{style: { fontSize: '0.90rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Printed name</InputLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="standard-basic" label="" variant="standard" value={formattedDate} fullWidth inputProps={{style: { fontSize: '0.90rem' }}}/>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Date</InputLabel>
                        </Grid>
                        <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom style={{ fontWeight: 'bold' }}>
                                <strong>For office use only:</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel control={<Checkbox />} sx={{ fontSize: '0.875rem'}} label="Written and verbal information was provided to the patient. (Advance health care directive packet)"
                            componentsProps={{
                                                typography: {
                                                    style: { fontSize: '0.90rem' } 
                                                }
                                            }} />
                        </Grid>
                        <Grid item>
                            <InputLabel htmlFor="input1" style={{ fontSize: '0.90rem' }}>Comments:</InputLabel>
                        </Grid>
                        <Grid item xs={10}>
                            <TextField id="standard-basic" label="" variant="standard" value={formData.comments} fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                            <img src={`${process.env.PUBLIC_URL}/Optum_logo.png`} alt="Optum Logo" style={{ width: '150px', height: '60px' }} />
                        </Grid>
                        <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom sx={{ fontSize: '0.875rem'}}>
                                If you are a guardian or court-appointed representative, you must provide a copy of your legal authorization to represent the patient.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom sx={{ fontSize: '0.875rem'}}>
                                The company does not discriminate on the basis of race, color, national origin, sex, age, or disability in health programs and activities.
                                We provide free services to help you communicate with us. This includes letters in other languages or large print. Or, you can ask for an
                                interpreter. To ask for help, please call 1-800-403-4160, TTY 711. ATENCIÓN: Si habla español (Spanish), hay servicios de asistencia de
                                idiomas, sin cargo, a su disposición. Llame al 1-800-403-4160, TTY 711. 請注意 ：如果您說中文 (Chinese) ，我們免費為您提供
                                語言協助服務 。請致電 ：1-800-403-4160, TTY 711 。
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom sx={{ fontSize: '0.875rem' }}>
                                Optum is a registered trademark of Optum, Inc. in the U.S. and other jurisdictions. All other trademarks are the property of
                                their respective owners. Because we are continuously improving our products and services, Optum reserves the right to change
                                specifications without prior notice. Optum is an equal opportunity employer.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom sx={{ fontSize: '0.875rem'}}>
                                © 2024 Optum, Inc. All rights reserved. 23684 01/24
                            </Typography>
                        </Grid>
                        <Grid item xs={12} textAlign={'right'}>
                            <Typography variant="h6" gutterBottom>
                              4
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
};

export default PatientRegistration;